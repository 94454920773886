import React from "react";

import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Container, Box, Grid, Typography } from "@mui/material";

const News = ({ data }) => {
  const news = data.allNewsJson.nodes;

  return (
    <Container maxWidth="lg" marginTop={10}>
      <Grid
        item
        xs={12}
        textAlign="center"
        marginTop={10}
        style={{ marginBottom: "2em" }}
      >
        <Typography variant="h3">Published news</Typography>
      </Grid>

      {news.map((post) => {
        const url = post.pdf.publicURL;
        const text = post.text;
        const link = post.link;
        return (
          <Grid
            container
            marginTop={{ md: 5, xs: "4em" }}
            marginBottom={7}
            alignItems="center"
          >
            <Grid
              item
              xs={12}
              textAlign="center"
              justifyContent={{ xs: "center", sm: "space-evenly" }}
            >
              <Box>
                <div className="press_news">
                  <div className="news_width1">
                    <a href={url} style={{ color: "black" }} download>
                      <GatsbyImage
                        image={getImage(post.img1)}
                        key=""
                        style={{
                          height: "auto",
                          maxWidth: "350px",
                        }}
                      />
                    </a>
                  </div>
                  <div className="news_width2">
                    <a href={link}>
                      <p style={{ color: "black", fontWeight: "600" }}>
                        {text}
                      </p>
                    </a>
                  </div>
                  <div className="news_width1">
                    {" "}
                    <GatsbyImage
                      image={getImage(post.img2)}
                      key=""
                      style={{
                        height: "auto",
                        maxWidth: "350px",
                      }}
                    />
                  </div>
                </div>
              </Box>
            </Grid>
          </Grid>
        );
      })}
    </Container>
  );
};

export default News;
