import React from "react";

import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Container, Grid, Typography } from "@mui/material";

const PressRelease = ({ data }) => {
  const pictures = data.allPicturesJson.nodes;

  return (
    <Container maxWidth="lg">
      <Grid
        container
        marginTop={5}
        justifyContent={{ xs: "space-between", sm: "space-evenly" }}
      >
        <Grid item xs={12} textAlign="center" style={{ marginBottom: "2em" }}>
          <Typography variant="h3">Pictures</Typography>
        </Grid>
        {pictures.map((post) => {
          const zip = post.zip.publicURL;
          return (
            <Grid
              item
              xs={12}
              md={4}
              marginTop={3}
              justifyContent={{ xs: "center" }}
            >
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <GatsbyImage
                  image={getImage(post.img)}
                  key=""
                  style={{
                    height: "auto",
                    maxWidth: "350px",
                  }}
                />

                <a
                  href={zip}
                  itemProp="url"
                  target="t_blank"
                  className="links_press"
                  download
                >
                  Download images
                </a>
              </div>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

export default PressRelease;
