import React from "react";

import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Container, Grid, Typography } from "@mui/material";

const PressRelease = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;
  const pressReleases = data.allInfoJson.nodes;

  return (
    <Container maxWidth="lg">
      <Grid
        container
        marginTop={5}
        justifyContent={{ xs: "center", sm: "space-evenly" }}
      >
        <Grid item xs={12} textAlign="center" style={{ marginBottom: "2em" }}>
          <Typography variant="h3">Press releases</Typography>
        </Grid>{" "}
        {pressReleases.map((post) => {
          const title = post.title;
          const pdfURL = post.pdf.publicURL;
          const date = post.date;
          const zip = post.zip.publicURL;
          return (
            <Grid item xs={12} key={title}>
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header className="press_flex">
                  <div className="press_width1">
                    <a href={pdfURL} target="t_blank">
                      {" "}
                      <GatsbyImage
                        image={getImage(post.image)}
                        key=""
                        alt={title}
                        imgStyle={{
                          borderRadius: "5px",
                          boxShadow: "1px 1px 1px 2px rgba(0, 0, 0, 0.05)",
                        }}
                        style={{
                          borderRadius: "5px",
                          boxShadow: " 1px 1px 1px 2px rgba(0, 0, 0, 0.05)",
                          height: "auto",
                          maxWidth: "500px",
                        }}
                      />
                    </a>
                  </div>

                  <div className="press_width2">
                    <div style={{ marginTop: "0%" }}>
                      {title ===
                      "CUSTOM SURGICAL RECEIVES INVESTMENT FROM LUMINATE NY AFTER WINNING OUTSTANDING GRADUATE AWARD " ? (
                        <a
                          href="https://drive.google.com/drive/folders/1hx10ZbVh3qGXh1v-0oWL5xBLdG2qw0CX?usp=sharing"
                          itemProp="url"
                          target="t_blank"
                          className="link_arc"
                        >
                          <Typography
                            variant="h5"
                            color="black"
                            fontWeightBlack={700}
                          >
                            {title}
                          </Typography>
                        </a>
                      ) : (
                        <a
                          href={pdfURL}
                          itemProp="url"
                          target="t_blank"
                          className="link_arc"
                        >
                          <Typography
                            variant="h5"
                            color="black"
                            fontWeightBlack={700}
                          >
                            {title}
                          </Typography>
                        </a>
                      )}
                    </div>{" "}
                    <div style={{ color: "black", marginTop: "3%" }}>
                      <Typography>{date}</Typography>
                    </div>
                  </div>
                  <a
                    href={zip}
                    itemProp="url"
                    target="t_blank"
                    style={{ textAlign: "center" }}
                    className="links_press press_width3"
                    download
                  >
                    Download images
                  </a>
                </header>
              </article>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

export default PressRelease;
