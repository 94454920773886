import React from "react";
import Layout from "../components/layout/layout";
import Seo from "../components/layout/seo";
import { graphql } from "gatsby";
import { Container, Box, Typography } from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import PressRelease from "../components/misc/press-release";
import Logos from "../components/misc/logos";
import Pictures from "../components/misc/pictures";
import News from "../components/misc/news";

let cs_theme = createTheme({
  palette: {
    primary: {
      main: "#FFA100",
    },
    secondary: {
      main: "#00C5B7",
    },
    white: "#FFFFFF",
    black: "#202026",
    purple: "#8F4FFF",
  },
  typography: {
    fontFamily: ["Red Hat Display"].join(","),
  },
});
cs_theme = responsiveFontSizes(cs_theme);

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined";

const Press = ({ data, location }) => {
  function reveal() {
    var reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }

  if (isBrowser) {
    window.addEventListener("scroll", reveal);
  }

  const siteTitle = data.site.siteMetadata.title;

  return (
    <ThemeProvider theme={cs_theme}>
      <Layout location={location} title={siteTitle}>
        <Seo title="Press | Custom Surgical" />
        <Box className="press">
          <Container maxWidth="lg">
            <Typography
              variant="h3"
              color={"white"}
              fontWeight="bold"
              padding={{ xs: 8, sm: 8, md: 12 }}
              style={{
                display: "flex",
                height: "60vh",
                alignItems: "flex-end",
                textShadow: "#000 1px 0 10px",
              }}
            >
              Press
            </Typography>
          </Container>
        </Box>
        <Container maxWidth="lg">
          <Typography variant="h6" padding={{ xs: 5, sm: 10, md: 10 }}>
            Here you will find some of our releases and also materials for using
            Custom Surgical brand according to our guidelines. To get in touch
            with us, just send us an e-mail:{" "}
            <a href="mailto:giuliana@customsurgical.co" className="links_press">
              giuliana@customsurgical.co
            </a>
          </Typography>
        </Container>

        <PressRelease data={data} />
        <div style={{ marginTop: "7em" }}></div>
        <Logos data={data} />
        <div style={{ marginTop: "7em" }}></div>
        <Pictures data={data} />
        <div style={{ marginTop: "7em" }}></div>
        <News data={data} />
      </Layout>
    </ThemeProvider>
  );
};

export default Press;

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
    allInfoJson {
      nodes {
        id
        date(formatString: "")
        zip {
          publicURL
        }
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        title
        pdf {
          publicURL
        }
      }
    }
    allPicturesJson {
      nodes {
        zip {
          publicURL
        }
        img {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    allLogoJson {
      nodes {
        zip {
          publicURL
        }
        img {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    allNewsJson {
      nodes {
        pdf {
          publicURL
        }
        img1 {
          childImageSharp {
            gatsbyImageData
          }
        }
        img2 {
          childImageSharp {
            gatsbyImageData
          }
        }
        link
        text
      }
    }
  }
`;
